import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Link from 'next/link'
import Head from 'next/head'
import LocalAPI from '../api/LocalAPI'
import { connect } from "react-redux";
import NoSSR from 'react-no-ssr'
import ReactGA from 'react-ga'
import { consoleLog, print } from '../managers/Logger'
import Constants from '../components/Constants'
import { Default, Mobile } from './ViewTypes'
import Container from 'react-bootstrap/Container';
import Router from 'next/router'
import {searchText} from '../store';
import { AiOutlineArrowRight } from 'react-icons/ai'
import Image from 'react-bootstrap/Image'
import { getUserImage } from '../managers/ImageHelper';
import UtilsManager from '../managers/UtilsManager'
import MetricsManager from '../managers/MetricsManager'
class Header extends React.Component {

	static defaultProps = {
		type: "curation"
	}

	constructor(props) {
		super(props)

		this.state = {
			isTop: true,
			type: props.type,
			isSearchActive: false,
			search: props.search,
		}
		this.utilsManager = new UtilsManager();
		this.metricsManager = new MetricsManager();
	}

	componentDidMount() {
		document.addEventListener('scroll', () => {
			const isTop = window.scrollY < 100;
			if (isTop !== this.state.isTop) {
				this.onScroll(isTop);
			}
		});

		const sessionStorage = window.sessionStorage;
		const context = sessionStorage.getItem("context");
		if (context) {
			this.setState({ type: context });
		}
		if(Router.pathname === '/search') {
			this.setState({isSearchActive: true})
		}
	}

	onScroll(isTop) {
		this.setState({ isTop });
	}

	getNavStyle() {

		if (this.state.isTop && this.props.scrollColorChange === true) {
			return { backgroundColor: 'rgba(0, 0, 0, 0)' }
		} else {
			return { backgroundColor: 'white', boxShadow: Constants.boxShadow}
		}
	}

	getNavTextStyle() {
		if (this.state.isTop && this.props.scrollColorChange === true) {
			return { color: 'white' }
		} else {
			return null
		}
	}

	getNavImageStyle() {
		return { padding: '2px',}
	}
	
	getDropdownJSXStyle() {
		if (this.state.isTop && this.props.scrollColorChange == true) {
			return <style global jsx>{
				`.dropdown-toggle.nav-link { 
					color: white !important;
				}
				
				.dropdown-item {
					color: black !important;
				}
				`
			}</style>
		} else {
			return null
		}
	}

getNavCollapseStyle() {
		if (this.state.isTop && this.props.scrollColorChange) {
			return <style>{`
				.navbar-light span.navbar-toggler-icon {
  				background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  				color: white;
				}`
			}
			</style>
		} else {
			null
		}
	}

	getMyEventsLink() {
		var promoterAccount = false
		var accessAccount = false
		if (this.props.user.promoter_set !== undefined && this.props.user.promoter_set !== null) {
			promoterAccount = this.props.user.promoter_set.length !== 0
		}

		if (this.props.user.accountaccess_set !== undefined && this.props.user.accountaccess_set !== null) {
			accessAccount = this.props.user.accountaccess_set.length !== 0
		}

		if (promoterAccount || accessAccount) {
			return <NavDropdown.Item eventKey="4.2" href="/myevents" style={this.getNavTextStyle()}>My Events</NavDropdown.Item>
		} else {
			return null
		}
	}


	async logOut() {
		const localApi = new LocalAPI()
		const result = await localApi.logout()
		print(result)
	}

	handleDiscoverClick() {
		ReactGA.event({
			category: 'User',
			action: 'discover',
			label: 'Navbar'
		})
	}

	handleDownloadClick() {
		ReactGA.event({
			category: 'User',
			action: 'download',
			label: 'Navbar'
		})
	}

	handleCreateEventClick() {
		ReactGA.event({
			category: 'Event',
			action: 'create_click',
			label: 'Navbar'
		})
	}

	handleLogin() {
		ReactGA.event({
			category: 'User',
			action: 'login',
			label: 'Navbar'
		})
	}

	getNavBarHref() {
		if (this.props.homepage) { 
			return this.props.homepage
		}
		if (this.props.guideId) {
			return `/passport/guide/${this.props.guideId}`
		}
		if (this.props.appDomain === "witheta") {
			return `/`
		}
		return `https://www.movement.fm/spaces`
	}

	getDomainUrl() { 
		if (this.props.appDomain === "witheta") {
			return `witheta.com`
		}
		return `movement.fm`;
	}

	getHref(textType) {
		if (textType === "room") {
			return "/spaces"
		}
		if (textType === "event") {
			return this.props.user !== undefined ? "/create" : "/signup?dest=create"
		}
		if (textType === "submit") {
			return this.props.user !== undefined ? "/submit" : "/signup?dest=submit"
		}
	}

	getNavs() {
		return (
			<Nav className="ml-auto align-items-center">
				
				{/* {this.props.user === undefined || this.props.user === null && this.state.type === "curation" && <Nav.Item onClick={() => this.handleDownloadClick()}>
					<Nav.Link href="https://movement.launchaco.com/" style={this.getNavTextStyle()}>Download</Nav.Link>
				</Nav.Item>} */}

				
				{/* {(this.props.user && this.isSpacesHeader()) && <Nav.Item>
					<Nav.Link href="/myspaces" style={this.getNavTextStyle()}>Dashboard</Nav.Link>
				</Nav.Item>} */}

				{this.isSpacesHeader() && <Nav.Item>
					<Nav.Link href="/claim_space" style={{...this.getNavTextStyle(), ...{backgroundColor: Constants.primaryETAButtonColor, color: 'white', borderRadius: 10}}}>Claim space</Nav.Link>
				</Nav.Item>}

				{this.isETAHeader() && !this.isSpacesHeader() && !this.props.noDownload && <Nav.Item onClick={() => this.metricsManager.logEvent("click", {label: "download", source: 'header'})}>
					<Nav.Link href="/downloads" style={this.getNavTextStyle()}>Download</Nav.Link></Nav.Item>}

				{this.isETAHeader() && !this.isSpacesHeader() && <NavDropdown title="Event" id="nav-dropdown" >
					<NavDropdown.Item eventKey="4.1" href={this.getHref("event")} style={this.getNavTextStyle()}>Create</NavDropdown.Item>
					<NavDropdown.Item eventKey="4.2" href={this.getHref("submit")} style={this.getNavTextStyle()}>Submit</NavDropdown.Item>
					{/* {this.props.user !== null && <NavDropdown.Item eventKey="4.3" href="/myevents" style={this.getNavTextStyle()}>My events</NavDropdown.Item>} */}
				</NavDropdown>}
{/* 
				{this.isSpacesHeader() && <NavDropdown title="Create" id="nav-dropdown" >
					<NavDropdown.Item eventKey="4.1" href="/spaces_create" style={this.getNavTextStyle()}>Space</NavDropdown.Item>
				</NavDropdown>} */}

				{!this.props.user && !this.isSpacesHeader() && <Nav.Item onClick={() => this.handleLogin()}>
					<Nav.Link href={`/login`}
						eventKey="link-1" style={this.getNavTextStyle()}>Log in</Nav.Link>
				</Nav.Item>}

				{/* {!this.props.user && !this.isSpacesHeader() && <Nav.Item onClick={() => this.handleLogin()}>
					<Nav.Link href={`/admin-login`}
						eventKey="link-admin" style={this.getNavTextStyle()}>Admin Log in</Nav.Link>
				</Nav.Item>} */}

				{this.props.user && !this.isSpacesHeader() &&  <Nav.Item>
					<Nav.Link href="/profile" style={this.getNavImageStyle()}>
						<Image style={{width: '30px', height: '30px', marginLeft: '10px'}} src={getUserImage(this.props.user)} roundedCircle />
					</Nav.Link>
				</Nav.Item>}

				{this.props.user !== null && !this.isSpacesHeader() && <NavDropdown alignRight title={this.utilsManager.getTruncatedText(this.props.user.username,8)} id="nav-dropdown" >
					<NavDropdown.Item eventKey="4.1" href="/profile" style={this.getNavTextStyle()}>Account</NavDropdown.Item>
				</NavDropdown>}
				{this.getDropdownJSXStyle()}
			</Nav>
		)
	}

	getOgTags() {
		var descriptionStr = "From turn ups, to virtual events, discover experiences where blackness is celebrated."
		var defaultTitleStr = "ETA: Discover hip-hop, afrobeat and black experiences curated by locals"
		var imageUrl = Constants.etaLogoUrl;
		if (this.isSpacesHeader()) {
			descriptionStr = "A new way to be together online that puts you in the same room no matter where you are."
			defaultTitleStr = "Spaces - immersive 3D group video chat"
			imageUrl = Constants.spacesLogoUrl
		}
		if (!this.props.ignoreOgTags) { 
			return (<React.Fragment>
			{!this.props.ignoreTitle && <title>{this.props.title ? this.props.title : defaultTitleStr}</title>}
			<meta name="description" content={this.props.description ? this.props.description : descriptionStr} key="description"/>
			<meta property="og:title" content={this.props.title ? this.props.title : defaultTitleStr} key="og_title"/>
			<meta property="og:description" content={this.props.description ? this.props.description : descriptionStr} key="og_description"/>
			<meta name="twitter:card" content="summary_large_image" />
			<meta property="og:image" content={this.props.imageUrl ? this.props.imageUrl : imageUrl } key="og_image"/>
			</React.Fragment>)
		}
	}

	getHead() {
		if (this.props.noHead) {
			return null
		} else {
			return (<Head>
				{this.getOgTags()}
				<meta name="viewport" content="initial-scale=1.0, width=device-width" />
				{!this.props.noStripe && <script src="https://js.stripe.com/v3/"></script>}
				{this.isSpacesHeader() && <link rel="shortcut icon" type="image/x-icon" href={Constants.spacesLogoUrl} />}
				{this.isETAHeader() && <link rel="shortcut icon" type="image/x-icon" href={Constants.etaLogoUrl} />}
				{this.props.canonicalUrl && <link rel="canonical" href={this.props.canonicalUrl} />}
				<link href="https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap" rel="stylesheet" />
				<link href="https://fonts.googleapis.com/css?family=Nerko+One:400,500,700,900&display=swap" rel="stylesheet" />
			</Head>)
		}
	}

	isSpacesHeader() {
		return this.props.appDomain === "movement" || this.props.type === "spaces";
	}

	isETAHeader() {
		return this.props.appDomain === "witheta" || this.props.type === "curation";
	}

	getImage() {
		if (this.state.isTop && this.props.scrollColorChange) {
			if (this.isSpacesHeader()) {
				return "/static/movement_white.png";
			} else {
				return "/static/eta_green.png";
			}
		}
		if (this.isSpacesHeader()) {
			return "/static/movement_black.png"
		} else {
			return "/static/eta_black.png";
		}

	}

	getImageHeight() {
		if (this.isSpacesHeader()) {
			return 16
		}
		return 25;
	}

	getImageWidth() {
		if (this.isSpacesHeader()) {
			return null
		}
		return 80;
	}

	getHomepageAppDomain() {
		if (this.props.type) {
			return this.props.type
		}
		if (this.props.appDomain === "eta") {
			return "/curation";
		} else {
			return "/spaces"
		}
	}

	handleSearch = () => {
		this.setState({ isSearchActive: !this.state.isSearchActive }, () => {
			if(!this.state.isSearchActive) {
				this.setState({search: ''}, () => {
					this.props.dispatch(searchText(this.state.search))
				})
			}
		})
	}

	handleSearchTextChange = (e) => {
		this.setState({search: e.target.value}, () => {
			this.props.dispatch(searchText(this.state.search))
		})
	}

	handleRedirectToSearch = () => {
		consoleLog(Router.pathname);
		this.metricsManager.logEvent("search", { value: this.state.search});
		if(this.state.search !== '' && Router.pathname !== '/search') {
			Router.push(`/search`)
		}
	}

	onKeyUp = (event) => {
		if (event.charCode === 13) {
		  this.setState({ search: event.target.value }, () => {
			  this.handleRedirectToSearch();
		  });
		}
	  }

	getNavComponents() { 
		return <React.Fragment>
				<Navbar.Brand href={this.getNavBarHref()} style={{ display: 'flex' }}>
						<img
							alt=""
							src={this.getImage()}
							height={this.getImageHeight()}
							width={this.getImageWidth()}
							className="d-inline-block align-top"
						/>
					</Navbar.Brand>
					{this.props.showEventSearchBar &&
						this.renderSearchBar()
					}
					<NoSSR>
						<Navbar.Toggle aria-controls="basic-navbar-nav" style={{outline: 'none'}} />
					</NoSSR>
					<Navbar.Collapse className="justify-content-end">
						<Nav className="ml-auto">
							<NoSSR>
								{this.getNavs()}
							</NoSSR>
						</Nav>
					</Navbar.Collapse>
				</React.Fragment>
	}

	renderSearchBar = () => {
		return (
			<div style={{
				width: 'calc(100% - 60%)'
			}}>
				<Mobile>
					<div>
						<div style={{
							position: 'absolute',
							padding: '10px 10px 10px 15px',
							textAlign: 'right',
							right: '70px',
							top: '5px',
						}} onClick={this.handleSearch}>
							<img src="https://gameplan-image-urls.s3.us-east-2.amazonaws.com/search.png"
								style={{
									display: 'inline-block',
									width: '24px',
									height: '24px',
									color: '#aaa',
									top: 20,
									marginLeft: '10px',
								}}
							/>
						</div>

						<div style={{
							display: this.state.isSearchActive === true ? 'flex' : 'none',
							position: 'fixed',
							top: '0',
							left: '0',
							background: '#fff',
							// display: 'flex',
							alignItems: 'center',
							flexWrap: 'wrap',
							width: '100%'
						}}>

							<div style={{
								width: 'calc(100% - 60px)',
								position: 'relative',
								padding: '10px 10px 10px 15px',
							}}>
								<div onClick={this.handleRedirectToSearch} style={{
									position: 'absolute',
									top: '16px'
								}}>
									<img src={'https://gameplan-image-urls.s3.us-east-2.amazonaws.com/search.png'}
										style={{
											display: 'block',
											width: '24px',
											height: '24px',
											color: '#aaa',
											marginLeft: '10px',
										}}
									/>
								</div>

								{this.props.search != "" && <div style={{position: 'absolute', right: 0, height: '100%', display: 'flex'}}
									onClick={this.handleRedirectToSearch}>
									<div style={{height: '50%'}}>
									<Button style={{borderRadius: '50%'}} variant="dark"><AiOutlineArrowRight color="white" /></Button>
									</div>
								</div>}

								<input style={{
									display: 'block',
									width: '100%',
									height: 'calc(2.25rem + 2px)',
									padding: '.375rem .75rem',
									fontFamily: 'Roboto',
									fontStyle: 'normal',
									fontWeight: 'normal',
									fontSize: '16px',
									letterSpacing: '0.75px',
									lineHeight: '28px',
									color: '#828282',
									backgroundColor: '#EFF0F7',
									backgroundClip: 'padding-box',
									border: '1px solid #ced4da',
									borderRadius: '20px',
									paddingLeft: '2.5rem',
									outline: 'none'
								}} onChange={this.handleSearchTextChange} value={this.state.search} 
								onKeyPress={this.onKeyUp} 
								onBlur={this.onKeyUp}
								type="text" placeholder="Search events" />
							</div>
							<div>
								<div style={{
									width: '60px',
									textAlign: 'center'
								}} onClick={this.handleSearch}>
									<img src={'https://gameplan-image-urls.s3.us-east-2.amazonaws.com/close.png'}
										style={{
											display: 'block',
											width: '16px',
											height: '16px',
											color: '#fff',
											top: 20,
											marginLeft: '10px'

										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</Mobile>
				<Default>
					<div style={{
						width: '100%',
						margin: '0px 20px',
						borderRadius: '20px',
						backgroundColor: '#EFF0F7',
					}}>
						<div onClick={this.handleRedirectToSearch}>
							<img src={'https://gameplan-image-urls.s3.us-east-2.amazonaws.com/search.png'}
								style={{
									position: 'absolute',
									zIndex: 2,
									display: 'block',
									width: '16px',
									height: '16px',
									color: '#aaa',
									top: '20px',
									marginLeft: '10px'

								}}
							/>
						</div>
						<input style={{
							display: 'block',
							height: 'calc(2.25rem + 2px)',
							padding: '.375rem .75rem',
							fontFamily: 'Roboto',
							fontStyle: 'normal',
							fontWeight: 'normal',
							fontSize: '16px',
							letterSpacing: '0.75px',
							lineHeight: '28px',
							color: '#828282',
							backgroundColor: '#EFF0F7',
							backgroundClip: 'padding-box',
							border: '1px solid #ced4da',
							borderRadius: '20px',
							paddingLeft: '2.5rem',
							outline: 'none',
							width: '100%'
						}} onChange={this.handleSearchTextChange} value={this.state.search} onKeyPress={this.onKeyUp} type="text" placeholder="Search events" />
					</div>
				</Default>
			</div>

		)
	}

	render() {
		return (
			<div style={this.props.style}>
				{this.getHead()}
				<Navbar ref={this.props.navRef} fixed="top" expand="md" style={this.getNavStyle()}>
					{this.props.useContainer && <Container style={this.props.containerStyle}>
						{this.getNavComponents()}
					</Container>}
					{!this.props.useContainer && this.getNavComponents()}
				</Navbar>
				{this.getNavCollapseStyle()}

				<style jsx global>{`				
				html, body {
					width: auto!important; overflow-x: hidden!important
				} 
 				body { 
 					font-family: 'Roboto', sans-serif !important;
		 		}

 				.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 { 
 					font-weight: 600 !important;
 				}
	 	`}
				</style>
			</div>
		)
	}
}

export default connect(state => state)(Header)